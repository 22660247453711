<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form>
              <b-form-group>
                <label for="input-1">Email Address <span style="color: red">*</span></label>
                <b-form-input id="input-1" v-model="form.email" placeholder="Enter Email Address" :class="{
                  'is-invalid': submitted && $v.form.email.$error,
                }"></b-form-input>

                <div class="error" v-if="!$v.form.email.email">
                  <span>
                    Please Enter Valid Email.
                  </span>
                </div>
                <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                  <span v-if="!$v.form.email.required">Email is required.</span>
                </div>
              </b-form-group>
              <b-form-group>
                <label for="input-2">Username <span style="color: red">*</span></label>
                <b-form-input id="input-2" v-model="form.username" placeholder="Enter Name" :class="{
                  'is-invalid': submitted && $v.form.username.$error,
                }"></b-form-input>
                <div v-if="submitted && !$v.form.username.required" class="invalid-feedback">Username is
                  required.</div>
              </b-form-group>
              <b-form-group>
                <label for="input-3">Password <span style="color: red">(Update if necessary)</span> </label>
                <b-form-input id="input-3" v-model="form.password" type="password" autocomplete="new-password"
                  placeholder="Enter Password"></b-form-input>
                  <span class="error" v-if="form.password != '' && !$v.form.password.valid">Password Must be Minimum 8 Chars and
                    Atleast
                    -
                    1 Uppercase , 1 Lowercase, 1 Numeric Character</span>
              </b-form-group>
              <b-button type="submit" variant="primary" class="mr-2" @click.prevent="updateProfile">Update Profile
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Profile from '../../../mixins/ModuleJs/profile'
import MixinRequest from "../../../mixins/request";
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [MixinRequest, Profile],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Edit Profile",
      items: [
        {
          text: "Back",
        },
        {
          text: "Data",
        },
      ],
      submitted: false,
    };
  },
  validations: {
    form: {
      email: { required, email },
      username: { required },
      password: {
        minLength: minLength(1),
        maxLength: maxLength(8),
        valid(value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        }
      },
    },
  },
};

</script>
