import {
  editProfile
} from '../../js/path'

export default {
  data() {
    return {
      form: {
        id: '',
        email: '',
        username: '',
        password: '',
      },
      admin_id: this.admin_id
    }
  },
  methods: {
    async updateProfile() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          if(this.$route.name == 'profile' && this.$v.form.username.$error && this.$v.form.email.$error) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields"
            });
            return false;
          }
        }
        this.$store.commit("loader/updateStatus", true);
        const url = editProfile.updateProfile;
        let dataAppend = new FormData();
        dataAppend.append('id', this.form.id);
        dataAppend.append('username', this.form.username);
        dataAppend.append('email', this.form.email);
        dataAppend.append('password', this.form.password);
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchProfile(admin_id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = editProfile.fetchProfile + "/" + admin_id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;

          if (responseData.email != "" && responseData.email != null) {
            this.form.email = responseData.email;
          }

          if (responseData.username != "" && responseData.username != null) {
            this.form.username = responseData.username;
          }

          this.form.id = responseData.id;
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  mounted() {
    let admin_id = localStorage.getItem('admin_id');
    this.fetchProfile(admin_id);
  }
}